import React from 'react'
import Router from 'next/router'
import Head from 'next/head'
import NProgress from 'nprogress'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { nanoid } from 'nanoid'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/pt-br'
import { Font } from '@react-pdf/renderer'

import GodotWrapper from '../components/godot-wrapper'
import GodotBridge from '../components/godot-bridge'
import AppVersion from '../components/app-version'
import CornerButtons from '../components/corner-buttons'
import { store } from '../app/store'

import version from '../public/version.json'

import '@fortawesome/fontawesome-svg-core/styles.css'

import '../styles/nprogress.css'
import '../styles/globals.scss'

dayjs.extend(LocalizedFormat)
dayjs.locale('pt-br')

Font.registerHyphenationCallback((word) => [word])

if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: '63d6d68d6bb69507ef3ce3fdecb320bd',
    appVersion: version.version,
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
      const user = store?.getState?.()?.user
      event.setUser(user?.email_hash, '', user?.fullname)
    }
  })
}

NProgress.configure({ showSpinner: true })

Router.onRouteChangeStart = () => NProgress.start()
Router.onRouteChangeComplete = () => NProgress.done()
Router.onRouteChangeError = () => NProgress.done()

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

if (typeof window !== 'undefined' && !window.sessionStorage['sessionId']) {
  window.sessionStorage['sessionId'] = nanoid()
}

function SafeHydrate ({ children }) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}

function MyApp ({ Component, pageProps }) {
  const handle = useFullScreenHandle()

  return (
    <SafeHydrate>
      <ErrorBoundary>
        <Provider store={store}>
          <FullScreen handle={handle}>
            <Head>
              <title>Ingredion Training</title>
              <meta name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Head>

            <GodotWrapper />
            <GodotBridge />
            <Component {...pageProps} fullScreenHandle={handle} />
            <Toaster />
            <AppVersion />
            <CornerButtons fullScreenHandle={handle} />
          </FullScreen>
        </Provider>
      </ErrorBoundary>
    </SafeHydrate>
  )
}

export default MyApp
