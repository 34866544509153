import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUserData } from '../../app/features/user/userSlice'

import version from '../../public/version.json'
import styles from './styles.module.scss'

export default function AppVersion() {
  const user = useSelector(selectUserData)

  useEffect(() => console.log(version))

  return <div className={styles.version}>Version: {version.version} | Usuario: {user?.user_key ?? "Not logged in"}</div>
}
