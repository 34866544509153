import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  auth: false,
  data: {},
  logs: []
}

export const userSlice = createSlice({
  name: 'user',

  initialState,

  reducers: {
    setUserAuth: (state, action) => {
      state.auth = true
      state.data = { _id: action.payload }
    },

    updateUserData: (state, action) => {
      state.data = { ...state.data, ...action.payload }
    },

    addLog: (state, action) => {
      state.logs.push(action.payload)
    },

    logoutReload: (state) => {
      state.auth = initialState.auth
      state.data = initialState.data
      state.logs = initialState.logs
      setTimeout(() => location?.reload?.(), 100)
    },

    logout: (state) => {
      state.auth = initialState.auth
      state.data = initialState.data
      state.logs = initialState.logs
    }
  }
})

export const { setUserAuth, updateUserData, addLog, logoutReload, logout } = userSlice.actions

export const selectUserAuth = (state) => state.user.auth
export const selectUserData = (state) => state.user.data
export const selectLogs = (state) => state.user.logs

export default userSlice.reducer
