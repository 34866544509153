import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '../loading'
import GodotCanvas from '../godot-canvas'
import { selectStart, startedEngine } from '../../app/features/engine/engineSlice'

import godotInfo from '../../public/godot.json'

export default function GodotWrapper () {
  const dispatch = useDispatch()
  const start = useSelector(selectStart)
  const [loading, setLoading] = useState(true)

  function onProgress (current, total) {
    console.log('Progress: ', current, total)
    if (current === true) {
      setLoading(false)
      dispatch(startedEngine())
      console.log('Engine has started!')
    }
  }

  useEffect(() => {
    setLoading(start)
  }, [start])

  return (
    <>
      {/* Godot is loaded from the beginning but not visible */}
      {loading && <Loading />}
      <GodotCanvas path={`/godot/${godotInfo.name}`} onProgress={onProgress} start={start} />
    </>
  )
}
