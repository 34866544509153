import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import Bugsnag from '@bugsnag/js'

import { achievementToast, errorToast } from '../../app/lib/toast'
import { patchUser, patchUserAchievement, patchUserGame } from '../../app/lib/api'
import { addLog, selectLogs, selectUserAuth, selectUserData } from '../../app/features/user/userSlice'
import { startedEngine } from '../../app/features/engine/engineSlice'
import { panSound, pitchSound, playSound, stopSound } from '../../app/lib/audio'
import dayjs from 'dayjs'

export default function GodotBridge () {
  const auth = useSelector(selectUserAuth)
  const user = useSelector(selectUserData)
  const logs = useSelector(selectLogs)
  const router = useRouter()
  const dispatch = useDispatch()
  const [hasUpdatesCallback, setHasHasUpdatesCallback] = useState(false)
  const [quizData, setQuizData] = useState({})

  const on_started = useCallback(async () => {
    console.log('GodotBridge#on_started')
    setHasHasUpdatesCallback(true)
    await dispatch(startedEngine())
  }, [dispatch, setHasHasUpdatesCallback])

  const navigate = useCallback(async (route) => {
    console.log('GodotBridge: Navigate to:', route)
    router.push(route).then()
  }, [router])

  const complete_achievement = useCallback(async (achievement_key) => {
    console.log('GodotBridge#complete_achievement', achievement_key)
    const response = await patchUserAchievement(dispatch, { achievement_key })
    achievementToast(response)
  }, [dispatch])

  const complete_level = useCallback(async (game_key, level, points) => {
    console.log('GodotBridge#complete_level', game_key, level, points)
    const response = await patchUserGame(dispatch, { game_key, level, points })
    achievementToast(response)
  }, [dispatch])

  const update_ink = useCallback(async (savegame_ink, savegame_data) => {
    console.log('GodotBridge#update_ink', savegame_ink, savegame_data)
    await patchUser(dispatch, { savegame_ink, savegame_data })
  }, [dispatch])

  const get_user = useCallback(() => user, [user])

  const get_quiz_data = useCallback(() => quizData, [quizData])

  const log_action = useCallback(async (log_name, log_id) => {
    console.log('GodotBridge#log_action', log_name, log_id)
    dispatch(addLog({ log_name, log_id }))
  }, [dispatch])

  const training_done = useCallback(async (log_name, log_id) => {
    console.log('GodotBridge#training_done', log_name, log_id)
    try {
      const response = await patchUser(dispatch, { training_done: true })

      if (!response?.ok) {
        console.error(response)
        errorToast(response?.message)
      }
    } catch (error) {
      console.error(error)
      errorToast()
      Bugsnag.notify(error)
    }
  }, [dispatch])

  const request_quiz_data = useCallback(async () => {
    console.log('GodotBridge#request_quiz_data')
    try {
      if (logs) {
        const iso9001 = dayjs(logs.filter((log) => log.log_id === 'iso9001').reverse()[0]?.created_at)
        const institutional_video = dayjs(logs.filter((log) => log.log_id === 'institutional_video')[0]?.created_at)
        const intruder = dayjs(logs.find((log) => log.log_id === 'intruder')?.created_at)

        const randomDates = (date) => ([
          date, date.add(25, 'minutes'), date.add(-15, 'minutes'), date.add(2, 'hours'), date.add(-1, 'day')
        ].map((d) => d.format('llll')))

        setQuizData({
          iso9001: randomDates(iso9001),
          institutional_video: randomDates(institutional_video),
          intruder: randomDates(intruder)
        })
      }
    } catch (error) {
      console.error(error)
      errorToast()
      Bugsnag.notify(error)
    }
  }, [logs])

  // Listen for route changes and notify godot
  useEffect(() => {
    let lastRoute = null
    const onRouteChangeStart = (url) => {
      if (window?.godot_navigate_callback) {
        console.log('GodotBridge: Notifying godot about page navigation')

        let newRoute = null
        if (/\/content.*/.test(url)) {
          newRoute = '/contents'
        } else if (/\/achievements.*/.test(url)) {
          newRoute = '/achievements'
        } else if (/\/leaderboard.*/.test(url)) {
          newRoute = '/achievements'
        } else if (url === '/about') {
          newRoute = '/about'
        } else {
          console.log('Unknown route:', url)
          return
        }

        if (lastRoute !== newRoute) {
          window.godot_navigate_callback(newRoute)
          lastRoute = newRoute
        }
      }
    }

    router.events.on('routeChangeStart', onRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [router])

  // Setup godot bridge functions
  useEffect(() => {
    window.godot_bridge = {
      on_started,
      navigate,
      complete_achievement,
      complete_level,
      update_ink,
      get_user,
      get_quiz_data,
      log_action,
      training_done,
      request_quiz_data,
      play_sound: playSound,
      stop_sound: stopSound,
      pan_sound: panSound,
      pitch_sound: pitchSound
    }
    return () => delete window.godot_bridge
  })

  // Notify godot when the user changes
  useEffect(() => {
    if (window?.godot_updates_callback) {
      console.log('GodotBridge: Notifying godot about updates')
      window.godot_updates_callback()
    }
  }, [hasUpdatesCallback, user, quizData])

  return null
}
