import { updateUserData } from '../features/user/userSlice'

export async function patchUser (dispatch, body) {
  await dispatch(updateUserData(body))
  return { ok: true }
}

export async function fetchUserAchievements (auth) {
  return []
}

export async function patchUserAchievement (auth, body) {
  return { ok: true }
}

export async function patchUserGame (auth, body) {
  return { ok: true }
}
