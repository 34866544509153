import { useEffect, useRef, useState } from 'react'

import useScript from '../../app/lib/useScript'

const Phase = {
  CREATED: 0,
  READY: 1,
  PRELOADED: 2,
  STARTING: 3,
  STARTED: 4,
  ERROR: -1
}

export default function GodotCanvas ({ path, onProgress, start = true }) {
  const status = useScript(`${path}.js`)
  const canvasRef = useRef()
  const [phase, setPhase] = useState(Phase.CREATED)
  const [engine, setEngine] = useState()

  // Wait for useScript to load the engine
  useEffect(() => {
    if (phase !== Phase.CREATED) return

    console.log('useScript', status)
    if (engine || status !== 'ready') return

    setEngine(new Engine())
    setPhase(Phase.READY)
  }, [phase, status, engine])

  // Preload the engine
  useEffect(() => {
    if (phase !== Phase.READY) return
    if (!engine) return

    async function doPreload () {
      await Promise.all([
        engine.init(path),
        engine.preloadFile(`${path}.pck`)
      ])
      setPhase(Phase.PRELOADED)
    }

    doPreload().then()
  }, [phase, engine, path])

  // Start the engine
  useEffect(() => {
    if (phase !== Phase.PRELOADED) return
    if (!start) return

    console.log('About to start engine...')
    setPhase(Phase.STARTING)

    async function doStart () {
      const canvas = canvasRef.current
      const args = ['--main-pack', `${path}.pck`]
      console.log(`Starting engine at ${path}...`)
      await engine.start({ canvas, onProgress, args })
      setPhase(Phase.STARTED)
      onProgress?.(true)
    }

    setTimeout(() => doStart().then(), 100)
  }, [phase, path, onProgress, start, engine, canvasRef])

  return (
    <canvas ref={canvasRef} id='godot-canvas' style={{ position: 'absolute' }} />
  )
}
