import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  start: false,
  started: false
}

export const engineSlice = createSlice({
  name: 'engine',

  initialState,

  reducers: {
    startEngine: (state) => {
      state.start = true
    },

    startedEngine: (state) => {
      state.started = true
    },

    stopEngine: (state) => {
      state.start = false
    }
  }
})

export const { startEngine, startedEngine, stopEngine } = engineSlice.actions

export const selectStart = (state) => state.engine.start
export const selectStarted = (state) => state.engine.started

export default engineSlice.reducer
