import toast from 'react-hot-toast'

import AchievementToast from '../../components/achievement-toast'

export function errorToast (message) {
  toast(message ?? 'Ocorreu um erro, por favor tente novamente dentro de alguns instantes')
}

export function achievementToast (body) {
  if (body.achievements) {
    body.achievements.forEach((achievement) => achievementToast({ achievement }))
  }

  if (body.achievement) {
    toast.custom(<AchievementToast {...body} />)
  }
}
