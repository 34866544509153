import LoadingAnimation from '../loading-animation'

import styles from './styles.module.scss'

export default function Loading () {
  return <div className={styles.center}>
    <LoadingAnimation />
    <p>Carregando...</p>
  </div>
}
