import { useEffect } from 'react'

import { playSound } from '../../app/lib/audio'

import styles from './styles.module.scss'

export default function AchievementToast ({ achievement, points }) {
  useEffect(() => playSound('general/achievement'))

  return (
    <div className={styles.achievement}>
      <div className={styles.icon}>🏆</div>
      <div className={styles.text}>
        <div>Nova conquista</div>
        <div>{achievement.title}</div>
      </div>
      <div className={styles.points}>{points}</div>
    </div>
  )
}
